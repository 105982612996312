import { searchClient } from "@algolia/client-search";
import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  window.Alpine.data("search", () => ({
    searchQuery: "",
    searchConfig: {},
    searchClient: null,
    searchLoading: false,

    init() {
      this.searchConfig = window.searchConfig;
      this.searchClient = searchClient(
        this.searchConfig.appId,
        this.searchConfig.key,
      );

      const urlParams = new URLSearchParams(window.location.search);
      const searchQuery = urlParams.get("s");
      if (searchQuery) {
        this.searchQuery = searchQuery;
        this.search(true);
      }

      window.addEventListener("popstate", () => {
        const urlParams = new URLSearchParams(window.location.search);
        const searchQuery = urlParams.get("s");
        if (searchQuery && searchQuery !== this.searchQuery) {
          this.searchQuery = searchQuery;
        }
      });

      this.$watch("searchQuery", () => this.search());
    },

    search(onPageLoad = false) {
      if (this.searchQuery.length < 2) {
        return;
      }

      this.searchLoading = true;

      let searchUrl = `/?s=${this.searchQuery}`;
      window.history.pushState({ path: searchUrl }, "", searchUrl);

      if (!onPageLoad) {
        this.buildBreadcrumbs();
        this.changeMetaTitle();
      }

      this.searchClient
        .searchSingleIndex({
          indexName: this.searchConfig.index,
          searchParams: {
            query: this.searchQuery,
            facets: ["*"],
          },
        })
        .then((response) => {
          Alpine.store("search", {
            results: response.hits,
            total: response.nbHits,
            facets: response.facets,
            query: this.searchQuery,
          });

          let container = this.searchConfig.containerTemplate;
          let main = document.querySelector("main");
          main.outerHTML = container;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },

    buildBreadcrumbs() {
      let breadcrumbs = document.querySelector("[data-breadcrumbs]");
      if (breadcrumbs) {
        breadcrumbs.remove();
      }

      let main = document.querySelector("main");
      let breadcrumbsElement = this.searchConfig.breadcrumbTemplate;
      breadcrumbsElement = breadcrumbsElement.replace("%s", this.searchQuery);

      main.insertAdjacentHTML("beforebegin", breadcrumbsElement);
    },

    changeMetaTitle() {
      let title = document.querySelector("title");
      title.innerText = this.searchConfig.metaTitleTemplate.replace(
        "%s",
        this.searchQuery,
      );
    },
  }));
});
